.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

:root {
  --main-bg-color: rgba(31, 31, 31, 1);
  --blue-accent: rgb(43 77 186);
  --offwhite: rgb(255 250 238);
  --average-top-color: rgb(190 192 172);

  --width2x2: calc((177.5 / 430) * min(100vw, 450px));
  --width1x1: calc((72.5 / 430) * min(100vw, 450px));
  --width2x4: calc((380 / 430) * min(100vw, 450px));
  --height1: calc(((72.5+14+10) / 430));
  --height2: calc(((177.5+14+10) / 430));
  --defaultPaddingX: calc(((25 / 430) * min(100vw, 450px)) / 2);
  --defaultPaddingY: calc(((20 / 430) * min(100vw, 450px)) / 2);
  --iconPaddingX: calc(((30 / 430) * min(100vw, 450px)) / 2);
  --textHeight: calc((14 / 430) * min(100vw, 450px));
  --textPadding: calc((10 / 430) * min(100vw, 450px));
}

.App-header {
  background-color: var(--main-bg-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
